import React, {useEffect} from "react";
import {useAuth0} from '@auth0/auth0-react';
export const Login = () => {
    const {isLoading, isAuthenticated, error, user, loginWithPopup, logout, getAccessTokenSilently} = useAuth0();

    useEffect(() => {
        if (!isLoading) {
        const getToken = async () => {
            try {
                await getAccessTokenSilently();
                // localStorage.setItem('token', token)
                // apiClient.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                // if(tokenExpired){
                //     dispatch(setUserField({tokenExpired: false}))
                // }
                // setTokenFetched(true)
            } catch (err: any) {
                // console.log(err?.message);
            }
        };
        void getToken();
        }
    }, [isAuthenticated, isLoading, getAccessTokenSilently]);

    return (
        <>
            {isLoading && <p>{'Loading...'}</p>}
            {error && <p>Oops... {error.message}</p>}
            {isAuthenticated &&
                <div className={'column'}>
                    <p>{user?.name}</p>
                    <button className={'button-login'}
                            onClick={() => logout({returnTo: window.location.origin})}
                    >
                        {'Log out'}
                    </button>
                </div>
            }
            {!isAuthenticated &&
                <button className='button-login'
                        onClick={loginWithPopup}
                >
                    {'Login'}
                </button>
            }
        </>
    )
}