import './App.css';
import React, {useEffect} from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {Login} from "./Login";


function App() {
    const redirectUri = () => {
        if (typeof window !== 'undefined') {
            return window?.location.origin
        }
    }
    // const onRedirectCallback = () => {
    //     location?.push(window.location.pathname);
    // };
    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN_TESTING as string}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
            audience="https://dev-ayuwa5n3linizcbp.us.auth0.com/api/v2/"
            scope="read:current_user update:current_user_metadata"
            useRefreshTokens
            redirectUri={redirectUri()}
            // onRedirectCallback={onRedirectCallback}
            cacheLocation="localstorage"
        >
            <div className="App column">
               <Login/>
            </div>
        </Auth0Provider>
    );
}

export default App;
